import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginPage from "./components/common/LoginPage";
import CompanyPage from "./components/pages/Company";
import HomePage from "./components/pages/HomePage";
import Dashboard from "./components/common/DashBoardPage";
import StorePage from "./components/pages/Store";
import CompanyDetails from "./components/pages/CompanyDetails";
import StoreDetails from "./components/pages/StoreDetails";
import User from "./components/pages/User";
import UserDetails from "./components/pages/UserDetails";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import UserHomePage from "./components/pages/UserHomePage";
import UserDownloadPage from "./components/pages/UserDownloadPage";
import {
  AdminProtectedPages,
  UserProtectedPages,
} from "./components/common/ProtectedRoute";
export const AuthContext = React.createContext();

function App() {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
    }
  );

  const authContext = React.useMemo(
    () => ({
      signIn: async (data) => {
        dispatch({ type: "SIGN_IN", token: "dummy-auth-token" });
      },
      signOut: () => dispatch({ type: "SIGN_OUT" }),
    }),
    []
  );

  useEffect(() => {
    const userToken = Cookies.get("userToken");
    if (userToken) {
      dispatch({ type: "SIGN_IN", token: userToken });
    } else {
      dispatch({ type: "SIGN_OUT", token: userToken });
    }
  }, []);

  return (
    <AuthContext.Provider value={authContext}>
      <Router>
        {state.isSignout ? (
          <Routes>
            <Route path="*" element={<Navigate to={"/"} />} />
            <Route path="/" element={<LoginPage />} />
          </Routes>
        ) : (
          <Routes>
            <Route
              path="/user"
              element={
                <AdminProtectedPages>
                  <UserHomePage />
                </AdminProtectedPages>
              }
            />
            <Route
              path="/download"
              element={
                <AdminProtectedPages>
                  <UserDownloadPage />
                </AdminProtectedPages>
              }
            />
            <Route
              path="/"
              element={
                <UserProtectedPages>
                  <Dashboard />
                </UserProtectedPages>
              }
              children={
                <>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/companies" element={<CompanyPage />} />
                  <Route path="/stores" element={<StorePage />} />
                  <Route path="/users" element={<User />} />
                  <Route path="/company/create" element={<CompanyDetails />} />
                  <Route path="/store/create" element={<StoreDetails />} />
                  <Route path="/user/create" element={<UserDetails />} />
                </>
              }
            />
          </Routes>
        )}
      </Router>
      <ToastContainer />
    </AuthContext.Provider>
  );
}

export default App;
