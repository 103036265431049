import React, { useState, useEffect } from "react";
import "../../styles/Style.css";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { API_URL } from "../appConfig";
import Cookies from "js-cookie";

export default function StoreDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const isUpdate = location.state && location.state.storeData;
  let storeDetails;
  if (isUpdate != null) {
    storeDetails = location.state.storeData;
  }
  const { register, handleSubmit } = useForm({
    defaultValues: {
      storeName: storeDetails ? storeDetails.storeName : "",
      companyId: "",
      status: "true",
    },
  });
  const [companyList, setCompanyList] = useState([]);

  var header = {
    headers: {
      Authorization: Cookies.get("userToken"),
    },
  };

  var updateStoreHeader = {
    headers: {
      Authorization: Cookies.get("userToken"),
      storeId: storeDetails ? storeDetails.storeId : "",
      companyId: storeDetails ? storeDetails.companyId : "",
    },
  };
  const onSubmit = async (data) => {
    let newFormData;
    if (isUpdate) {
      newFormData = {
        storeName: data.storeName,
        status: "true",
      };
    } else {
      newFormData = {
        storeName: data.storeName,
        companyId: data.companyId,
        status: "true",
      };
    }
    if (isUpdate) {
      const apiUrl = API_URL + "admin/stores";
      axios
        .put(apiUrl, newFormData, updateStoreHeader)
        .then((response) => {
          navigate("/stores");
          if (response.status === 200) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.Message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      axios
        .post(API_URL + "admin/store/Register", newFormData, header)
        .then((response) => {
          navigate("/stores");
          if (response.status === 200) {
            toast.success(response.data.Message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.Message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  useEffect(() => {
    const apiUrl = API_URL + "admin/companies";
    axios
      .get(apiUrl, header)
      .then((response) => {
        const companies = response.data.companies;
        setCompanyList(companies);
      })
      .catch((error) => {
        console.error("Error fetching companies:", error);
      });
  }, []);
  return (
    <div className="mx-auto px-2 sm:px-5 py-5 w-full lg:w-1/2">
      <div className="cd-card py-16 p-4 sm:p-16">
        <h2 className="text-center font-bold text-lg mb-4">
          STORE {isUpdate ? "UPDATE" : "REGISTRATION"}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`grid ${
              !storeDetails ? "grid-cols sm:grid-cols-2" : "grid-cols-1"
            } gap-2 font-poppins`}
          >
            <div>
              <span className="font-semibold">Store Name</span>
              <input
                className="block border border-grey-light w-full p-3 rounded mb-4"
                {...register("storeName")}
                placeholder="Store Name"
                required
              />
            </div>
            {!storeDetails ? (
              <div>
                <span className="font-semibold">Company</span>
                <select
                  className="block border border-grey-light w-full p-3 rounded mb-4"
                  {...register("companyId")}
                  required
                >
                  <option value="">Company</option>
                  {companyList?.map((companyItem) => {
                    return (
                      <option
                        key={companyItem.companyId}
                        value={companyItem.companyId}
                      >
                        {companyItem.companyName}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
          </div>
          <div className="text-center mt-6">
            <input
              className="w-full md:w-[250px] bg-black text-white border-2 border-gray-300 rounded-md p-2"
              type="submit"
              value={isUpdate ? "Update" : "Register"}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
