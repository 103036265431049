import React, { useState, useEffect } from "react";
import "../../styles/Style.css";
import axios from "axios";
import EditIcon from "../../assets/ic_edit.svg";
import { API_URL } from "../appConfig";
import { useNavigate } from "react-router-dom";
import { Puff } from "react-loader-spinner";
import Cookies from "js-cookie";
import { LoadingFailed } from "../common/LoadingError";
import { DataNotAvailable } from "../common/NoDataError";

export default function Store() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedData, setSortedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(0);

  function filterData() {
    const formattedData = data?.map((store) => {
      return {
        ...store,
      };
    });

    const filteredData = formattedData.filter((store) =>
      store.storeName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSortedData(filteredData);
  }

  useEffect(() => {
    filterData();
  }, [searchTerm, data]);

  var header = {
    headers: {
      Authorization: Cookies.get("userToken"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  useEffect(() => {
    const apiUrl = API_URL + "admin/stores";
    axios
      .get(apiUrl, header)
      .then((response) => {
        setData(response.data.stores);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="records table-responsive">
      <div className="record-header">
        <div className="title">
          <span className="text-xl font-semibold">STORE DETAILS</span>
        </div>
        <div className="flex gap-4 py-2">
          <input
            type="search"
            placeholder="Search Store"
            className="border-2 border-gray-200 focus:outline-none rounded-lg px-4 w-[150px] sm:w-auto"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="w-[40px] h-[40px] bg-black hover:bg-gray-600 rounded-full text-white"
            onClick={() => navigate("/store/create")}
          >
            +
          </button>
        </div>
      </div>

      {loading ? (
        <div className="loader-container">
          <div className="loader">
            <Puff
              height="80"
              width="80"
              radius={1}
              color="#747474"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      ) : null}

      {error ? <LoadingFailed /> : null}

      {!loading && !error && (
        <div className="rounded-t-md overflow-x-auto">
          {sortedData ? (
            <table className="table-auto w-full rounded-t-md">
              <thead className="select-none">
                <tr>
                  <th>#</th>
                  <th>STORE ID</th>
                  <th>STORE NAME</th>
                  <th>STATUS</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {sortedData?.map((store, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{store.storeId}</td>
                    <td>{store.storeName}</td>
                    <td>
                      <span
                        className={`paid ${
                          store.status === true ? "completed" : "pending"
                        }`}
                      >
                        {store.status === true ? "Active" : "Expired"}
                      </span>
                    </td>
                    <td>
                      <div
                        className="flex justify-center"
                        onClick={() =>
                          navigate("/store/create", {
                            state: { storeData: store },
                          })
                        }
                      >
                        <img
                          className="cursor-pointer"
                          src={EditIcon}
                          alt="edit_icon"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <DataNotAvailable />
          )}
        </div>
      )}
    </div>
  );
}
