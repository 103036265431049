import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../App";
import HomeIcon from "../../assets/ic_home.svg";
import UserIcon from "../../assets/ic_user.svg";
import StoreIcon from "../../assets/ic_store.svg";
import CompanyIcon from "../../assets/ic_company.svg";
import Logout from "../../assets/ic_logout.svg";
import { ReactSVG } from "react-svg";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/NavBar.css";
import Cookies from "js-cookie";

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = useState();

  const { signOut } = useContext(AuthContext);

  const handleLogout = () => {
    Cookies.remove("userToken");
    Cookies.remove("userType");
    signOut();
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setPageTitle("H.");
        break;
      case "/companies":
        setPageTitle("C.");
        break;
      case "/stores":
        setPageTitle("S.");
        break;
      case "/users":
        setPageTitle("U.");
        break;
      default:
        setPageTitle("H.");
        break;
    }
  }, [location.pathname]);

  return (
    <div className="relative min-h-screen md:flex">
      <aside className="bg-black text-white h-[95vh] w-[80px] m-2 rounded-3xl flex flex-col justify-between overflow-y-auto">
        <span className="text-2xl font-extrabold text-center py-2 select-none">
          {pageTitle}
        </span>
        <div className="flex flex-col space-y-6">
          <nav>
            <div className="flex justify-center my-6">
              <ReactSVG
                fill={"#000"}
                className="cursor-pointer"
                src={HomeIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    "fill",
                    location.pathname === "/" ? "#ffff99" : "white"
                  );
                }}
                onClick={() => navigate("/")}
              />
            </div>
            <div className="flex justify-center my-6">
              <ReactSVG
                fill={"#000"}
                className="cursor-pointer"
                src={CompanyIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    "fill",
                    location.pathname === "/companies" ? "#ffff99" : "white"
                  );
                }}
                onClick={() => navigate("companies")}
              />
            </div>
            <div className="flex justify-center my-6">
              <ReactSVG
                fill={"#000"}
                className="cursor-pointer w-5"
                src={StoreIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    "fill",
                    location.pathname === "/stores" ? "#ffff99" : "white"
                  );
                }}
                onClick={() => navigate("stores")}
              />
            </div>
            <div className="flex justify-center my-6">
              <ReactSVG
                fill={"#000"}
                className="cursor-pointer"
                src={UserIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    "fill",
                    location.pathname === "/users" ? "#ffff99" : "white"
                  );
                }}
                onClick={() => navigate("users")}
              />
            </div>
          </nav>
        </div>

        <nav>
          <span className="flex justify-center">
            <img
              src={Logout}
              alt="logout_icon"
              className="img_nav select-none"
              onClick={handleLogout}
            />
          </span>
        </nav>
      </aside>
    </div>
  );
}
