import Lottie from "react-lottie";
import LoadingFailedAnimation from "../JSON/LoadingFailed.json";

export const LoadingFailed = () => {
  const defaultAnimationOptions = {
    loop: false,
    autoplay: false,
    animationData: LoadingFailedAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="w-[300px] sm:w-[400px] m-auto">
      <Lottie options={defaultAnimationOptions} />
    </div>
  );
};
