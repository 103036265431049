import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

export const UserProtectedPages = ({ children }) => {
  const loggedUserType = Cookies.get("userType");
  let userLoggedIn;
  if (loggedUserType === "3") {
    userLoggedIn = true;
  } else {
    userLoggedIn = false;
  }
  if (userLoggedIn) {
    return <Navigate to="/user" replace />;
  }

  return children;
};

export const AdminProtectedPages = ({ children }) => {
  const loggedUserType = Cookies.get("userType");
  let adminLoggedIn;
  if (loggedUserType === "1") {
    adminLoggedIn = true;
  } else {
    adminLoggedIn = false;
  }
  if (adminLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return children;
};
