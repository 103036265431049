import { useState, useContext } from "react";
import { API_URL } from "../appConfig";
import { AuthContext } from "../../App";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { IoCloudUploadOutline } from "react-icons/io5";
import UserWelcome from "../../assets/user.png";
import Logout from "../../assets/ic_logout.svg";
import { UserNavBar } from "../common/UserNavBar";

export default function UserHomePage() {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { signOut } = useContext(AuthContext);
  var header = {
    headers: {
      Authorization: Cookies.get("userToken"),
    },
  };

  const handleFileChange = (event) => {
    setUploadedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!uploadedFile) return;
    setIsLoading(true);
    const uploadUrl = API_URL + "customer/fileUpload";
    const formData = new FormData();
    formData.append("fileName", uploadedFile);
    axios
      .post(uploadUrl, formData, header)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setUploadedFile(null);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.Message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      });
  };
  const handleLogout = () => {
    Cookies.remove("userToken");
    Cookies.remove("userType");
    signOut();
  };
  return (
    <>
      <div className="flex items-center justify-between gap-4 bg-black w-full p-2 px-6">
        <div className="flex items-center gap-2 sm:gap-4">
          <img
            className="w-16 h-16 rounded-full mr-3 bg-white"
            src={UserWelcome}
            alt="user"
          />
          <div className="text-sm text-white">
            <span className="font-medium leading-none transition duration-500 ease-in-out">
              Hello User
            </span>
            <p>It’s good to see you again.</p>
          </div>
        </div>
        <div>
          <span
            onClick={handleLogout}
            className="flex justify-center text-white gap-2 items-center cursor-pointer"
          >
            Logout
            <img
              src={Logout}
              alt="logout_icon"
              className="img_nav select-none"
            />
          </span>
        </div>
      </div>
      <div>
        <UserNavBar />
        <div>
          <div>
            <div className="flex items-center justify-center w-full">
              <div className="flex flex-col items-center justify-center w-full p-16 sm:px-60 lg:px-96 border-2 border-gray-300 border-dashed rounded-lg bg-gray-100">
                <div className="flex flex-col items-center justify-center py-8">
                  <IoCloudUploadOutline
                    className="text-gray-400"
                    size={"50px"}
                  />
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="font-semibold">
                      Choose the file you want to upload
                    </span>
                  </p>
                </div>

                <div className="flex gap-4 items-center mb-2 w-full">
                  <label
                    htmlFor="fileInput"
                    style={{ cursor: "pointer" }}
                    className="bg-gray-500 text-white p-2 rounded-lg text-center"
                  >
                    Choose File
                    <input
                      type="file"
                      id="fileInput"
                      style={{
                        opacity: 0,
                        position: "absolute",
                        left: "-9999px",
                      }}
                      onChange={handleFileChange}
                    />
                  </label>
                  {uploadedFile && <p>{uploadedFile.name}</p>}
                </div>
                <div className="w-full">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 w-full text-white font-bold py-2 px-4 rounded"
                    onClick={handleUpload}
                    disabled={isLoading}
                  >
                    {isLoading ? "Uploading..." : "Upload"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
