import React, { useState, useEffect } from "react";
import { API_URL } from "../appConfig";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import "../../styles/Style.css";
import Cookies from "js-cookie";

export default function StoreDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const isUpdate = location.state && location.state.userData;
  let userDetails;
  if (isUpdate != null) {
    userDetails = location.state.userData;
  }
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      userName: userDetails ? userDetails.userName : "",
      password: userDetails ? userDetails.password : "",
      storeId: userDetails ? userDetails.storeId : "",
      userType: userDetails ? userDetails.userType : "",
    },
  });
  const [storeList, setStoreList] = useState("");
  const TextField = ({ Title, fieldName, onChange }) => {
    return (
      <div>
        <span className="font-semibold">{Title}</span>
        <input
          className="block border border-grey-light w-full p-3 rounded mb-4"
          {...register(`${fieldName}`)}
          placeholder={Title}
          onChange={onChange}
        />
      </div>
    );
  };

  useEffect(() => {
    const apiUrl = API_URL + "admin/stores";
    axios
      .get(apiUrl, header)
      .then((response) => {
        setStoreList(response.data.stores);
      })
      .catch((error) => {
        console.error("Error fetching stores:", error);
      });
  }, []);

  var header = {
    headers: {
      Authorization: Cookies.get("userToken"),
    },
  };
  var updateUserHeader = {
    headers: {
      Authorization: Cookies.get("userToken"),
      userId: userDetails ? userDetails.userId : "",
    },
  };
  const onSubmit = async (data) => {
    if (isUpdate) {
      const apiUrl = API_URL + "admin/users";
      axios
        .put(apiUrl, data, updateUserHeader)
        .then((response) => {
          navigate("/users");
          if (response.status === 200) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.Message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      axios
        .post(API_URL + "admin/Registration", data, header)
        .then((response) => {
          navigate("/users");
          if (response.status === 200) {
            toast.success(response.data.Message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.Message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  return (
    <div className="bg-grey-lighter min-h-screen flex flex-col m-auto sm:w-[500px]">
      <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
        <div className="bg-white px-6 py-8 rounded shadow-md text-black w-full">
          <h1 className="mb-8 text-2xl text-center">
            USER {isUpdate ? "UPDATE" : "REGISTRATION"}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              Title={"User Name"}
              fieldName="userName"
              onChange={(e) => setValue("userName", e.target.value)}
            />
            <TextField
              Title={"Password"}
              fieldName="password"
              onChange={(e) => setValue("password", e.target.value)}
            />
            <div>
              <span className="font-semibold">Store</span>
              <select
                className="block border border-grey-light w-full p-3 rounded mb-4"
                {...register("storeId")}
                required
              >
                <option value="">Store</option>
                {storeList
                  ? storeList.map((storeItem) => {
                      const isSelected =
                        storeItem?.storeId === isUpdate?.storeId;
                      return (
                        <option
                          key={storeItem.storeId}
                          value={storeItem.storeId}
                          selected={isSelected}
                        >
                          {isSelected
                            ? `${storeItem.storeName}`
                            : storeItem.storeName}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
            <div>
              <span className="font-semibold">User Type</span>
              <select
                className="block border border-grey-light w-full p-3 rounded mb-4"
                {...register("userType")}
                required
              >
                <option value="">User Type</option>
                <option value={2}>User</option>
                <option value={3}>Customer</option>
              </select>
            </div>
            <div className="text-center mt-6">
              <input
                className="w-full bg-black text-white border-2 border-gray-300 rounded-md p-2"
                type="submit"
                value={isUpdate ? "Update" : "Register"}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
