import React, { useState } from "react";
import axios from "axios";
import "../../styles/Company.css";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { API_URL } from "../appConfig";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Cookies from "js-cookie";

export default function CompanyDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const isUpdate = location.state && location.state.companyData;
  const [userType, setUserType] = useState(null);
  let companyDetails;
  if (isUpdate != null) {
    companyDetails = location.state.companyData;
  }
  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is mendatory")
      .min(3, "Password must be at 3 char long"),
    confirmPassword: Yup.string()
      .required("Password is mendatory")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });
  const { register, handleSubmit, setValue, errors } = useForm({
    // resolver: yupResolver(formSchema),
    defaultValues: {
      companyName: companyDetails ? companyDetails.companyName : "",
      licenseKey: companyDetails ? companyDetails.licenseKey : "",
      country: companyDetails ? companyDetails.country : "",
      address: companyDetails ? companyDetails.address : "",
      email: companyDetails ? companyDetails.email : "",
      userName: "",
      password: "",
      confirmPassword: "",
    },
  });

  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() + 1);
  const defaultValidityDate = currentDate.toISOString().split("T")[0];

  var header = {
    headers: {
      Authorization: Cookies.get("userToken"),
    },
  };
  var companyUpdateHeader = {
    headers: {
      Authorization: Cookies.get("userToken"),
      companyId: companyDetails ? companyDetails.companyId : "",
    },
  };

  const TextField = ({ Title, fieldName, onChange }) => {
    return (
      <div>
        <span className="font-semibold">{Title}</span>
        <input
          className="block border border-grey-light w-full p-3 rounded mb-4"
          {...register(`${fieldName}`, { required: true })}
          placeholder={Title}
          onChange={onChange}
        />
      </div>
    );
  };

  const [data] = useState();
  const onSubmit = async (data) => {
    let newFormData;
    if (isUpdate) {
      newFormData = {
        companyName: data.companyName,
        licenseKey: data.licenseKey,
        country: data.country,
        address: data.address,
        email: data.email,
        status: "true",
        validity: defaultValidityDate,
        isExpired: false,
      };
    } else {
      newFormData = {
        companyName: data.companyName,
        licenseKey: data.licenseKey,
        country: data.country,
        address: data.address,
        email: data.email,
        userName: data.userName,
        password: data.password,
        validity: defaultValidityDate,
        status: "true",
        userType: userType,
      };
    }
    const UpdateURL = API_URL + "admin/companies";
    const RegisrationURL = API_URL + "admin/company/Register";
    if (isUpdate) {
      axios
        .put(UpdateURL, newFormData, companyUpdateHeader)
        .then((response) => {
          navigate("/companies");
          if (response.status === 200) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.Message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      axios
        .post(RegisrationURL, newFormData, header)
        .then((response) => {
          navigate("/companies");
          if (response.status === 200) {
            toast.success(response.data.Message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.Message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  return (
    <div className="px-5 py-5 w-full">
      <div className="cd-card p-4 sm:p-16">
        <h2 className="text-center font-bold text-lg mb-8">
          COMPANY {isUpdate ? "UPDATE" : "REGISTER"}
        </h2>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols sm:grid-cols-2 gap-2 font-poppins">
              <TextField
                Title={"Company Name"}
                fieldName="companyName"
                onChange={(e) => setValue("companyName", e.target.value)}
              />
              <TextField
                Title={"License Key"}
                fieldName="licenseKey"
                onChange={(e) => setValue("licenseKey", e.target.value)}
              />
              <TextField
                Title={"Country"}
                fieldName="country"
                onChange={(e) => register("country")}
              />
              <TextField
                Title={"Address"}
                fieldName="address"
                onChange={(e) => register("address")}
              />
              <TextField
                Title={"Email"}
                fieldName="email"
                onChange={(e) => register("email")}
              />
              {!isUpdate ? (
                <TextField
                  Title={"User Name"}
                  fieldName="userName"
                  onChange={(e) => register("userName")}
                />
              ) : null}
              {!isUpdate ? (
                <>
                  <TextField
                    Title={"Password"}
                    fieldName="password"
                    onChange={(e) => register("password")}
                  />
                  <TextField
                    Title={"Confirm Password"}
                    fieldName="confirmPassword"
                    onChange={(e) => register("confirmPassword")}
                  />
                </>
              ) : null}
              {!isUpdate ? (
                <div>
                  <span className="font-semibold">User Type</span>
                  <select
                    className="block border border-grey-light w-full p-3 rounded mb-4"
                    onChange={(e) => setUserType(e.target.value)}
                    required
                  >
                    <option value="">User Type</option>
                    <option value={2}>User</option>
                    <option value={3}>Customer</option>
                  </select>
                </div>
              ) : null}
            </div>
            <div className="text-center mt-6">
              <input
                className="w-full md:w-[250px] bg-black text-white border-2 border-gray-300 rounded-md p-2"
                type="submit"
                value={isUpdate ? "Update" : "Register"}
              />
            </div>
          </form>
          <div>{data}</div>
        </div>
      </div>
    </div>
  );
}
