import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { API_URL } from "../appConfig";
import { AuthContext } from "../../App";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import UserWelcome from "../../assets/user.png";
import Logout from "../../assets/ic_logout.svg";
import { get } from "lodash-es";
import { UserNavBar } from "../common/UserNavBar";
import { DataNotAvailable } from "../common/NoDataError";
import { Puff } from "react-loader-spinner";
import { LoadingFailed } from "../common/LoadingError";
import { IoClose } from "react-icons/io5";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useNavigate, useLocation } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserHomePage() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { signOut } = useContext(AuthContext);
  const [fileName, setFileName] = useState(null);
  var header = {
    headers: {
      Authorization: Cookies.get("userToken"),
    },
  };

  const handleLogout = () => {
    Cookies.remove("userToken");
    Cookies.remove("userType");
    signOut();
  };

  const handleDownload = (file) => () => {
    const downloadFileUrl =
      API_URL + `customer/downloadExcelFile?fileName=${file}`;
    axios
      .get(downloadFileUrl, {
        params: {
          cacheBustTimestamp: Date.now(),
        },
        responseType: "blob",
        headers: {
          Accept: "application/octet-stream",
          Authorization: Cookies.get("userToken"),
        },
      })
      .then((response) => {
        downloadFile(response, file);
      });
  };
  const downloadFile = async (response, filename) => {
    const data =
      get(response, "payload.data", null) || get(response, "data", null);
    if (!(data instanceof Blob)) return;

    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${filename}`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  };

  const handleDelete = async () => {
    const deleteFileUrl =
      API_URL + `customer/deleteExcelFile?fileName=${fileName}`;
    await axios
      .delete(deleteFileUrl, header)
      .then((response) => {
        navigate("/download");
        if (response.status === 200) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.Message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    setOpen(false);
  };

  const handleClickOpen = (file) => {
    setFileName(file);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const apiUrl = API_URL + "customer/listFiles";
    axios
      .get(apiUrl, header)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        toast.error(error.response.data.Message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [data]);

  return (
    <>
      <div className="flex items-center justify-between gap-4 bg-black w-full p-2 px-6">
        <div className="flex items-center gap-2 sm:gap-4">
          <img
            className="w-16 h-16 rounded-full mr-3 bg-white"
            src={UserWelcome}
            alt="user"
          />
          <div className="text-sm text-white">
            <span className="font-medium leading-none transition duration-500 ease-in-out">
              Hello User
            </span>
            <p>It’s good to see you again.</p>
          </div>
        </div>
        <div>
          <span
            onClick={handleLogout}
            className="flex justify-center text-white gap-2 items-center cursor-pointer"
          >
            Logout
            <img
              src={Logout}
              alt="logout_icon"
              className="img_nav select-none"
            />
          </span>
        </div>
      </div>
      <div>
        <UserNavBar />
        {loading ? (
          <div className="loader-container">
            <div className="loader">
              <Puff
                height="80"
                width="80"
                radius={1}
                color="#747474"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </div>
        ) : null}

        {error ? <LoadingFailed /> : null}
        {!loading && !error && (
          <div>
            <>
              {data ? (
                <div className="relative overflow-x-auto">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          File Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Download File
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Delete File
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        ? data?.files?.map((file, index) => {
                            return (
                              <tr key={index} className="bg-white border-b">
                                <th
                                  scope="row"
                                  className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                                >
                                  {file}
                                </th>
                                <td className="px-6 py-2">
                                  <button
                                    className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600"
                                    onClick={handleDownload(file)}
                                  >
                                    Download
                                  </button>
                                </td>
                                <td className="px-6 py-2">
                                  <IoClose
                                    onClick={() => handleClickOpen(file)}
                                    className="m-auto text-white bg-red-400 rounded-full h-5 w-5 cursor-pointer"
                                  />
                                </td>
                              </tr>
                            );
                          })
                        : null}
                      <React.Fragment>
                        <Dialog
                          open={open}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleClose}
                          className="bg-white"
                        >
                          <DialogTitle>
                            {`Are you sure you want to delete this file?`}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              This Action cannot be undone. The file will be
                              deleted Permanently.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleDelete}>Yes</Button>
                          </DialogActions>
                        </Dialog>
                      </React.Fragment>
                    </tbody>
                  </table>
                </div>
              ) : (
                <DataNotAvailable />
              )}
            </>
          </div>
        )}
      </div>
    </>
  );
}
