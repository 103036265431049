import { IoCloudDownloadOutline, IoCloudUploadOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export const UserNavBar = () => {
  const navigate = useNavigate();
  return (
    <div>
      <ul className="flex flex-wrap gap-2 text-sm font-medium text-center select-none">
        <Link to="/user">
          <li className="cursor-pointer inline-flex gap-2 items-center justify-center p-4 border-b-2 bg-gray-600 hover:bg-gray-500 text-white">
            <IoCloudUploadOutline size={"20px"} />
            Upload File
          </li>
        </Link>
        <Link to="/download">
          <li className="cursor-pointer inline-flex gap-2 items-center justify-center p-4 border-b-2 bg-gray-600 hover:bg-gray-500 text-white">
            <IoCloudDownloadOutline size={"20px"} />
            Download File
          </li>
        </Link>
      </ul>
    </div>
  );
};
