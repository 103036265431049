import Lottie from "react-lottie";
import NoDataAnimation from "../JSON/NoDataAnimation.json";

export const DataNotAvailable = () => {
  const defaultAnimationOptions = {
    loop: false,
    autoplay: false,
    animationData: NoDataAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="w-[300px] sm:w-[400px] m-auto mt-5">
      <Lottie options={defaultAnimationOptions} />
    </div>
  );
};
