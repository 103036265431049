import React, { useState, useEffect } from "react";
import "../../styles/Style.css";
import axios from "axios";
import EditIcon from "../../assets/ic_edit.svg";
import { useNavigate } from "react-router-dom";
import { Puff } from "react-loader-spinner";
import Cookies from "js-cookie";
import { LoadingFailed } from "../common/LoadingError";
import { DataNotAvailable } from "../common/NoDataError";
import { API_URL } from "../appConfig";

export default function Company() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedData, setSortedData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  var header = {
    headers: {
      Authorization: Cookies.get("userToken"),
    },
  };
  async function getCompanyData() {
    const apiUrl = API_URL + "admin/companies";
    try {
      const response = await axios.get(apiUrl, header);
      setData(response.data.companies);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  function filterData() {
    const formattedData = data?.map((company) => {
      const Validity = new Date(company.validity);
      const statusClass = getStatusClass(Validity, company.status);
      const statusText = getStatusText(Validity, company.status);

      return {
        ...company,
        Validity,
        statusClass,
        statusText,
      };
    });

    const filteredData = formattedData.filter((company) =>
      company.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSortedData(filteredData);
  }

  function getStatusClass(validityDate, status) {
    const currentDate = new Date();
    if (validityDate < currentDate) {
      return "expired";
    } else if (status) {
      return "active";
    } else {
      return "inactive";
    }
  }

  function getStatusText(validityDate, status) {
    const currentDate = new Date();
    if (validityDate < currentDate) {
      return "Expired";
    } else if (status) {
      return "Active";
    } else {
      return "Inactive";
    }
  }

  useEffect(() => {
    getCompanyData();
  }, []);

  useEffect(() => {
    filterData();
  }, [searchTerm, data]);

  return (
    <div className="records table-responsive">
      <div className="record-header">
        <div className="title">
          <span className="text-xl font-semibold">COMPANY DETAILS</span>
        </div>
        <div className="flex gap-4 py-2">
          <input
            type="search"
            placeholder="Search Client"
            className="border-2 border-gray-200 focus:outline-none rounded-lg px-4 w-[150px] sm:w-auto"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="w-[40px] h-[40px] bg-black rounded-full text-white"
            onClick={() => navigate("/company/create")}
          >
            +
          </button>
        </div>
      </div>

      {loading ? (
        <div className="loader-container">
          <div className="loader">
            <Puff
              height="80"
              width="80"
              radius={1}
              color="#747474"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      ) : null}

      {error ? <LoadingFailed /> : null}

      {!loading && !error && (
        <div className="rounded-md overflow-x-auto">
          {sortedData ? (
            <table className="table-auto w-full rounded-t-md">
              <thead className="select-none">
                <tr>
                  <th>#</th>
                  <th>CLIENT</th>
                  <th>KEY</th>
                  <th>VALID DATE</th>
                  <th>ADDRESS</th>
                  <th>COUNTRY</th>
                  <th>STATUS</th>
                  <th className="flex justify-center">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((company, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{company.companyName}</td>
                    <td>{company.licenseKey}</td>
                    <td>{company.Validity.toLocaleDateString()} </td>
                    <td>{company.address}</td>
                    <td>{company.country}</td>
                    <td>
                      <span className={`paid ${company.statusClass}`}>
                        {company.statusText}
                      </span>
                    </td>
                    <td className="flex justify-center">
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          navigate("/company/create", {
                            state: { companyData: company },
                          })
                        }
                      >
                        <span>
                          <img src={EditIcon} alt="edit_icon" />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <DataNotAvailable />
          )}
        </div>
      )}
    </div>
  );
}
