import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../App";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { API_URL } from "../appConfig";
import Cookies from "js-cookie";

export default function LoginPage() {
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const navigate = useNavigate();
  const { signIn } = useContext(AuthContext);
  useEffect(() => {
    const userToken = Cookies.get("userToken");
    if (userToken) {
      navigate("/user");
    }
  }, [navigate]);
  var header = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const apiUrl = API_URL + "admin/login";
      const response = await axios.post(apiUrl, data, header);
      if (response.status === 200) {
        const userToken = response.data.userToken;
        const userType = response.data.userType;
        Cookies.set("userToken", userToken, { expires: 7 });
        Cookies.set("userType", userType, { expires: 7 });
        setResponseMessage(
          `Login successful. Response: ${JSON.stringify(response.data.message)}`
        );
        toast.success("Login " + response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (userType === 1) {
          signIn();
        } else {
          signIn();
          navigate("/user");
        }
      } else {
        setResponseMessage(`Login failed. Status: ${response.data.message}`);
      }
    } catch (error) {
      setResponseMessage(error.response.data.message);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setIsLoading(false);
  };
  return (
    <section>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Sign in to your account
            </h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                {...register("userName")}
                className="w-full bg-white border-2 border-gray-300 rounded-md p-2 my-2 focus:outline-none"
                placeholder="User Name"
                required
              />
              <input
                {...register("password")}
                className="w-full bg-white border-2 border-gray-300 rounded-md p-2 my-2 focus:outline-none"
                type="password"
                placeholder="Password"
                required
              />
              <button className="w-full bg-black text-white border-2 border-gray-300 cursor-pointer rounded-md p-2 focus:outline-none">
                {isLoading ? "Loading..." : "Login"}
              </button>
            </form>
            <p className="text-sm font-light text-red-600 text-center">
              {responseMessage}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
