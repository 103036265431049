import React from 'react'
import Navbar from '../common/NavBar'
import { Outlet } from 'react-router-dom'
import '../../App.css'

export default function Dashboard() {
    return (
        <div className='root_container'>
            <Navbar />
            <Outlet />
        </div>


    )
}