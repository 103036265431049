import React from "react";
import "../../styles/Home.css";
import UserWelcome from "../../assets/user.png";
import { BsBuildings } from "react-icons/bs";
import { BiSolidStore } from "react-icons/bi";
import { FaUserFriends } from "react-icons/fa";

export default function HomePage() {
  const Card = ({
    CardColor,
    Icon,
    ContentTitle,
    ContentData,
    IconBackground,
  }) => {
    return (
      <div className="relative flex flex-col justify-center overflow-hidden py-2">
        <div className="group h-[100%] relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 border-2 border-gray-200 transition-all duration-300 hover:-translate-y-1 hover:shadow-md max-w-sm sm:rounded-lg sm:px-10">
          <span
            className={`${CardColor} absolute top-10 z-0 h-20 w-20 rounded-full transition-all duration-300 group-hover:scale-[10]`}
          ></span>
          <div className="relative z-10 mx-auto max-w-md">
            <span
              className={`${CardColor} ${IconBackground} grid h-20 w-20 place-items-center rounded-full transition-all duration-300 `}
            >
              {Icon}
            </span>
            <div className="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
              <div className="pt-5 text-base font-semibold leading-7">
                <p>{ContentTitle}</p>
                <p className="text-2xl font-semibold m-0">{ContentData}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="w-full">
      <div className="px-4">
        <div className="flex items-center justify-end gap-4 border-b-2 bg-white w-full p-2 rounded-b-md">
          <div className="text-sm text-black">
            <span className="font-medium leading-none transition duration-500 ease-in-out">
              Hello Admin!
            </span>
            <p>It’s good to see you again.</p>
          </div>
          <img
            className="w-16 h-16 rounded-full mr-3 bg-gray-200"
            src={UserWelcome}
            alt="user"
          />
        </div>
        <div className="grid grid-cols sm:grid-cols-2 xl:grid-cols-3 gap-2 mb-2 m-auto">
          <Card
            CardColor={"bg-sky-400"}
            IconBackground={"group-hover:bg-sky-300"}
            Icon={<BsBuildings className="text-white" size={"30px"} />}
            ContentTitle={"Total Company Registered"}
            ContentData={"120"}
          />
          <Card
            CardColor={"bg-red-300"}
            IconBackground={"group-hover:bg-red-200"}
            Icon={<BiSolidStore className="text-white" size={"30px"} />}
            ContentTitle={"Total Store Registered"}
            ContentData={"120"}
          />
          <Card
            CardColor={"bg-orange-300"}
            IconBackground={"group-hover:bg-orange-200"}
            Icon={<FaUserFriends className="text-white" size={"30px"} />}
            ContentTitle={"Total User Registered"}
            ContentData={"120"}
          />
          <Card
            CardColor={"bg-rose-300"}
            IconBackground={"group-hover:bg-rose-200"}
            Icon={<BsBuildings className="text-white" size={"30px"} />}
            ContentTitle={"Total Company Registered"}
            ContentData={"120"}
          />
          <Card
            CardColor={"bg-teal-300"}
            IconBackground={"group-hover:bg-teal-200"}
            Icon={<BsBuildings className="text-white" size={"30px"} />}
            ContentTitle={"Total Store Registered"}
            ContentData={"120"}
          />
          <Card
            CardColor={"bg-fuchsia-300"}
            IconBackground={"group-hover:bbg-fuchsia-200"}
            Icon={<BsBuildings className="text-white" size={"30px"} />}
            ContentTitle={"Total Store Registered"}
            ContentData={"120"}
          />
        </div>
      </div>
    </div>
  );
}
